<template>
    <div class="camera">
        <div class="camera-list" v-if="showContent === 'list'">
            <!-- <CameraLevel @click-group="filterByGroup" @getgetDeviceGroup="getgetDeviceGroup"/> -->
            <div class="content">
                <div class="header">
                    <div class="c-title">
                        摄像头列表<span v-if="total > 0">({{total}})</span>
                    </div>
                    <a-form-model layout="inline" :model="filters">
                        <div>
                            <a-form-model-item>
                                <a-input-search v-model="keywoerd" placeholder="搜索摄像头名称" enter-button @search="search" />
                            </a-form-model-item>
                            <a-form-model-item label="设备状态">
                                <a-select v-model="filters.in_line" placeholder="请选择设备状态" style="width:210px;" :allowClear="true" @change="getCamera(1)">
                                    <a-select-option v-for="item in deviceStateList" :key="item.id">
                                        {{item.name}}
                                    </a-select-option>
                                </a-select>
                            </a-form-model-item>
                            <a-form-model-item label="抽图状态">
                                <a-select v-model="filters.device_state" placeholder="请选择抽图状态" style="width:210px;" :allowClear="true" @change="getCamera(1)">
                                    <a-select-option v-for="item in drawStateList" :key="item.id">
                                        {{item.name}}
                                    </a-select-option>
                                </a-select>
                            </a-form-model-item>
                        </div>
                    </a-form-model>
                </div>
                <div class="camera-box">
                    <a-empty v-if="tableData.length === 0"/>
                    <a-row v-else>
                        <a-spin :spinning="spinning" tip="加载中...">
                            <a-col :span="6" v-for="item in tableData" :key="item.device_id">
                                </a-checkbox>
                                <div class="device-content">
                                    <div class="img-box">
                                        <img :onerror="defaultCutImg" :src="item.img_link">
                                    </div>
                                    <div class="detail">
                                        <div class="contain">
                                            <div class="device-name overflow">{{item.device_name}}</div>
                                            <div class="draw-state">
                                                抽图状态:
                                                <span class="state">{{item.device_state | getDeviceState}}</span>
                                            </div>
                                        </div>
                                        <div class="footer">
                                            <div class="online box" v-if="item.in_line === 1">
                                                <div class="dot"></div>
                                                <div class="txt">在线</div>
                                            </div>
                                            <div class="offline box" v-else>
                                                <div class="txt">离线</div>
                                            </div>
                                            <div class="cameratype box">
                                                <div class="txt">
                                                    {{item.camera_type === 1?'枪机':'球机'}}
                                                </div>
                                            </div>
                                            <div class="box" style="cursor: pointer;" @click="checkCameraVideo(item.device_id)">画面
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </a-col>
                        </a-spin>
                    </a-row>
                    <div class="pagination-box">
                        <a-pagination show-quick-jumper show-size-changer :current="filters.page" :pageSize="filters.pageSize" 
                        :pageSizeOptions="pageSizeOptions" :total="total" @change="changePage" @showSizeChange="changeSize"/>
                    </div>
                </div>
            </div>
        </div>
        <CameraScreen
            v-if="showContent==='cameraVideo'" 
            @close="showContent = 'list'" 
            :checkedCamera="editCamData"
        />
    </div>  
</template>

<script>
import CameraLevel from "./components/cameraLevel";
import {getCameraList,deviceDetail} from "@/api/data";
import {generateParameter} from "@/libs/tool";
import CameraScreen from "./components/cameraScreen";
export default {
    components:{CameraLevel,CameraScreen},
    data(){
        return {
            showContent:'list',
            total:0,
            keywoerd:'',
            filters:{
                source:0,
                page:1,
                pageSize:8,
                search:'',
                device_group_id:'',
                device_state:undefined,
                in_line:undefined,
            },
            deviceStateList:[{id:1,name:'设备在线'},{id:0,name:'设备离线'}],
            drawStateList:[{id:0,name:'停止抽图'},{id:1,name:'正在抽图'},{id:2,name:'未配技能'}],
            tableData:[],
            total:0,
            checkAll:false,
            isDel:false,
            delItem:[],
            checkPage:[],
            defaultCutImg:'this.src="' + require('./images/default_camera_img.png') + '"',
            pageSizeOptions:['8', '16', '24', '32', '40'],
            spinning:false,
            editCamData:{},
        }
    },
    filters:{
        getDeviceState(val){
            let deviceState;
            switch(val){
                case 0:
                    deviceState = '停止抽图'
                    break;
                case 1:
                    deviceState = '正在抽图'
                    break;
                case 2:
                    deviceState = '未配技能'
                    break;
            };
            return deviceState;
        }
    },
    mounted(){
        this.getCamera();
    },
    methods:{
        search(){
            this.filters.search = this.keywoerd;
            this.getCamera(1);
        },
        changePage(page){
            this.getCamera(page);
        },
        changeSize(current, pageSize){
            this.filters.pageSize = pageSize;
            this.getCamera(1);
        },
        getCamera(page){ // 获取摄像头数据
            this.spinning = true;
            if(page){
                this.filters.page = page;
            }
            getCameraList(generateParameter(this.filters)).then(res => {
                this.spinning = false;
                if(res.code === 1){
                    let tableData = this.$getData(res.data.data);
                    this.tableData = tableData.map(item => {
                        if(this.delItem.indexOf(item.device_id) > -1){
                            item.checked = true;
                        }else{
                            item.checked = false;
                        }
                        return item;
                    });
                    // 判断当前页面是否已经全选 未全选取消全选框选中状态
                    let index = this.checkPage.indexOf(this.filters.page);
                    if(index === -1){
                        this.checkAll = false;
                    }else{
                        this.checkAll = true;
                    }
                    this.total = res.data.total;
                }
            })
        },
        checkCameraVideo(id){
            this.getCameraInfo(id,'cameraVideo')
        },
        editCam(data){ // 编辑摄像头
            this.getCameraInfo(data.device_id,'editCam')
        },
        getCameraInfo(id,type){
            deviceDetail({
                device_id: id
            }).then(res => {
                if(res.code === 1){
                    this.editCamData = res.data;
                    this.showContent = type;
                }
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.camera{
    height:100%;
    font-family: Source Han Sans CN;
    background: #F7F9FA;
    .camera-list{
        height:100%;
        display:flex;
        justify-content: space-between;
        .content{
            flex:1;
            margin-left:5px;
            display: flex;
            flex-direction: column;
            .header{
                flex:1;
                background: #fff;
                padding:6px 30px 0;
                display:flex;
                flex-direction: column;
                justify-content: space-evenly;
                box-sizing: border-box;
                .c-title{
                    font-size: 20px;
                    line-height: 28px;
                    color: rgba(0, 0, 0, 0.85);
                }
                ::v-deep .ant-form{
                    display:flex;
                    justify-content: space-between;
                    .button-box{
                        display:flex;
                        justify-content: right;
                        align-items: center;
                        .delbox{
                            margin-right:20px;
                        }
                    }
                }
            }
        }
        .camera-box{
            margin:21px 21px 30px;
            height:77.5%;
            box-sizing: border-box;
            ::v-deep .ant-empty{
                height:calc(100% - 53px);
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
            ::v-deep .ant-row{
                height:calc(100% - 53px);
                overflow: auto;
                .ant-col{
                    padding:9px 0.73%;
                    box-sizing: border-box;
                    height:48%;
                }
            }
            .device-content{
                height:100%;
                background: #fff;
                border: 1px solid #E2E2E2;
                border-radius: 4px;
                .img-box{
                    height:calc(100% - 118px);
                    width:100%;
                    overflow: hidden;
                    display:flex;
                    align-items: center;
                    justify-content: center;
                    background: #3C485D;
                    >img{
                        width:auto;
                        height:auto;
                        max-height: 100%;
                        max-width: 100%;
                        margin: 0;
                        padding: 0;
                    }
                }
                .detail{
                    .contain{
                        padding:0 0 15px 16px;
                        .device-name{
                            font-size: 16px;
                            line-height:40px;
                            height:40px;
                            color:rgba(0, 0, 0, 0.85);
                        }
                        .draw-state{
                            font-size: 14px;
                            line-height: 22px;
                            height:22px;
                            color:rgba(0, 0, 0, 0.45);
                        }
                    }
                    .footer{
                        display:flex;
                        justify-content: space-between;
                        align-items: center;
                        text-align: center;
                        box-shadow: inset 0px 1px 0px #F0F0F0;
                        .online{
                            display:flex;
                            align-items: center;
                            justify-content: center;
                            .dot{
                                width:6px;
                                height:6px;
                                border-radius: 50%;
                                background: #0066FF;
                            }
                            .txt{
                                color:#0066FF !important;
                                margin-left:7px;
                            }
                        }
                        .box{
                            width:33.3%;
                            height:16px;
                            font-size: 14px;
                            line-height:16px;
                            margin:12px 0;
                            .anticon-dash:hover{
                                color:#367BF5;
                            }
                            .txt{
                                color:#8C8C8C;
                            }
                        }
                        .online,.cameratype{
                            box-shadow: inset -1px 0px 0px #F0F0F0;
                        }
                    }
                }
            }
            .pagination-box{
                text-align: right;
                margin-top:21px;
            }
        }
    }   
}
.camera-dropdown-box{
    padding:7px 0;
    .dropdown-content{
        padding:0 16px;
        display:flex;
        align-items: center;
        font-size: 14px;
        line-height: 28px;
        height:28px;
        color: #347DFF;
        .title{
            margin-left:9px;
        }
    }
}
</style>