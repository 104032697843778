<template>
    <div class="content">
        <div class="header">
            <div class="back-box">
                <div class="back" @click="back">
                    <a-icon type="left" />
                </div>
                <div class="title">设备驾驶舱-画面</div>
            </div>
            <div class="cameraTab">
                <div :class="['camera-tab', activeIndex===0? 'active':'']" @click="activeIndex=0">设备画面</div>
                <div :class="['camera-tab', activeIndex===1? 'active':'']" @click="activeIndex=1">设备录像</div>
                <div :class="['camera-tab', activeIndex===2? 'active':'']" @click="activeIndex=2">设备分享</div>
                <div :class="['camera-tab', activeIndex===3? 'active':'']" @click="activeIndex=3">分屏展示</div>
            </div>
        </div>
        <div class="camera-tabpanes">
            <div class="video-box" v-if="activeIndex==0 || activeIndex==2">
                <CameraScreen ref="cameraScreen" :checkedCamera="checkedCamera"></CameraScreen>
            </div>
            <div class="device-frame" v-if="activeIndex===0">
                <div class="controller" v-if="checkedCamera.camera_type==2">
                    <div class="controller-top">
                        <div class="ptz-cell ptz-zooout" @mousedown="controlDirection('zoomout')" @mouseup="controlDirection('stop')"></div>
                        <div class="ptz-cell ptz-zoomin" @mousedown="controlDirection('zoomin')" @mouseup="controlDirection('stop')"></div>
                    </div>
                    <div class="controller-bottom">
                        <div class="ptz-cell ptz-up" @mousedown="controlDirection('up')" @mouseup="controlDirection('stop')"></div>
                        <div class="ptz-box">
                            <div class="ptz-cell ptz-left" @mousedown="controlDirection('left')" @mouseup="controlDirection('stop')"></div>
                            <div class="ptz-cell ptz-right" @mousedown="controlDirection('right')" @mouseup="controlDirection('stop')"></div>
                        </div>
                        <div class="ptz-cell ptz-down" @mousedown="controlDirection('down')" @mouseup="controlDirection('stop')"></div>
                    </div>
                </div>
            </div>
            <div class="videotape" v-if="activeIndex===1">
                <div style="width:90%;">
                    <Playback :videotapeUrl="videotapeUrl" :videoLoading="playloading"></Playback>
                </div>
                <div>
                    <PlaybackTime ref="playback" :checkedCamera='checkedCamera' @changeload="getload" @getVideoUrl="getVideoUrl"></PlaybackTime>
                </div>
            </div>
            <div class="date-box" v-if="activeIndex===1">
                <div calss="title">日期筛选</div>
                <div>
                    <a-date-picker :disabled-date="disabledDate" @change="changeDate"/>
                </div>
            </div>
            <div class="share" v-if="activeIndex===2">
                <div class="title">分享链接</div>
                <div class="share-href">
                    <div class="camera-href-box">
                        <div>{{shareHref}}</div>
                        <!-- <span @click="copyHref"></span> -->
                        <a-icon type="copy" @click="copyHref" />
                    </div>
                </div>
                <div class="title">扫码分享</div>
                <div class="QRcode">
                    <div class="qrBox">
                        <qr-code :href='shareHref'></qr-code>
                    </div>
                </div>
            </div>
            <div v-if="activeIndex===3" style="width:100%;">
                <div class="row visible-xs text-center">
                    <div class="player-btn-group">
                        <a-button v-for="list in playerBtnGroup" :key="list.num" @click.prevent="setPlayerLength(list.num)" :class="{'active' : playerLength == list.num}">{{list.name}}</a-button>
                        <a-button id="full-btn-medium" @click.prevent="fullscreen">全屏显示</a-button>
                    </div>
                </div>
                <div class="view-list row">
                    <div class="video-show">
                        <div class="player">
                            <div class="video" v-for="(player,index) in players" :key="index" @mousemove="resetCloseTimer(player)" @touchstart="resetCloseTimer(player)" :class="{'col-sm-12': playerLength == 1,'col-sm-6': playerLength == 4,'col-sm-4': playerLength == 9,}">
                                <a-spin :spinning="player.bLoading" tip="加载中...">
                                    <LivePlayer :videoUrl="player.url" live muted stretch alt="无画面"></LivePlayer>
                                </a-spin>
                                <div class="video-close overflow" v-show="player.url && player.bCloseShow" @click="closeVideo(index)">关闭</div>
                                <div class="video-close" v-show="!player.url && player.bCloseShow" @click="selectChannel(index,player)">选择通道</div>
                            </div>
                            <div v-if="showCameradeviceLevelData" class="modal">
                                <div class="modal-dialog">
                                    <div class="modal-content">
                                        <div style="height:100%;display:flex;justify-content:space-between;">
                                            <div class="tableLeft">
                                                <div class="tabs">
                                                    <span>设备架构</span>
                                                </div>
                                                <div class="deviceTree">
                                                    <div v-if="deviceLevelData.length!==0">
                                                        <a-tree
                                                            v-model="selectedKeys"
                                                            :tree-data="deviceLevelData"
                                                            :replaceFields="replaceFields"
                                                            @select="clickGroup"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tableRight">
                                                <a-input-search
                                                    style="margin-bottom:10px;"
                                                    placeholder="搜索设备名称"
                                                    v-model="search"
                                                    @search="searchDevice">
                                                </a-input-search>
                                                <a-spin style="height:100%;" :spinning="loading" tip="加载中...">
                                                    <a-table size="middle" :columns="columns" :pagination="false" :data-source="tableData" :customRow="click">
                                                        <span slot="type" slot-scope="camera_type">
                                                            <div class="alarm-info-statu" >{{camera_type === 1?'枪机':'球机'}}</div>
                                                        </span>
                                                    </a-table>
                                                </a-spin>
                                                <a-pagination :defaultCurrent="currentPage" :defaultPageSize="pageSize" :total="total" @change="changePage" />
                                                <div class="modal-btns">
                                                    <a-button type="primary" @click="showCameradeviceLevelData=false">取消</a-button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LivePlayer from '@liveqing/liveplayer';
import {cameraAjax} from "@/api/index.js";
import TimeRule from "@/components/timeRule";
import CameraScreen from "@/components/cameraScreen";
import Playback from "@/components/playback";
import qrCode from '@/components/qRcode';
import PlaybackTime from "@/components/playbackTime";
import dayjs from "dayjs";
import {deviceGroup,getCameraList} from "@/api/data";
export default {
    props:["checkedCamera"],
    components: {LivePlayer,TimeRule,qrCode,CameraScreen,Playback,PlaybackTime},
    data(){
        return {
            // 播放
            videoUrl: "",
            activeIndex:0,

            // 回放
            timeValue:'',

            // 分享
            shareUrl: "",
            // 分屏
            players: [],
            playerLength: 1,
            channelListDlgTitle: "",
            // protocol: "",
            showCameradeviceLevelData:false,
            tableData:[],              
            columns:[
                {
                    title: '编号',
                    dataIndex: 'index',
                    align:'center',
                },
                {
                    title: '设备类型',
                    dataIndex: 'camera_type',
                    align:'center',
                    scopedSlots: { customRender: 'type' },
                },
                {
                    title: '设备名',
                    dataIndex: 'device_name',
                    align:'center',
                },
            ],
            deviceLevelData:[],
            currentPage:1,
            pageSize:7,
            total:0,
            filterId:'',
            search:'',
            keyword:'',
            loading:false,
            index:'',
            showFullscreen:false,
            day:'',
            touchTimer:0,
            id:'',
            disabledDate(current) {
                return current && current > dayjs().endOf('day');
            },
            selectedKeys:[],
            replaceFields:{
                children:'children', 
                title:'group_name',
                key:'id'
            },
            videotapeUrl:'',
            playloading:false,
        }
    },
    watch: {
        activeIndex(val) {
            if (val == 1) {
            } else if(val == 0 || val == 2){
                this.timeValue = "";
            }else{
                this.setPlayerLength(this.playerLength);
            }
        },
    },
    computed: {
        playerBtnGroup() {
            var list = [{
                num: 1,
                name: "单屏"
            }, {
                num: 4,
                name: "四分屏"
            }, {
                num: 9,
                name: "九分屏"
            }];
            return list;
        },
        indexMethod () {
            return (index) => {		// 对计算属性进行传参
                return this.$getIndex(index,this.currentPage,this.pageSize,this.total);
            }
        }
    },
    methods:{
        getVideoUrl(url){
            this.videotapeUrl = url;
        },
        getload(val){
            this.playloading = val;
        },
        controlDirection(statu){
            if(!this.checkedCamera){return}
            if(!this.checkedCamera.movieurls){
                this.$message.error("摄像头信息不全无法查看画面")
                return
            }
            //  + '&speed=100'
            let data = this.checkedCamera.movieurls + 'api/v1/control/ptz?' + 'serial=' + this.checkedCamera.national_num +'&code=' + this.checkedCamera.channel + '&command=' + statu;
            cameraAjax.get(data).then(data => {})
        },
        copyHref() { //复制
            let url = this.shareHref;
            let oInput = document.createElement('input');
            oInput.value = url;
            document.body.appendChild(oInput);
            oInput.select(); // 选择对象;
            document.execCommand("Copy"); // 执行浏览器复制命令
            this.$message.success('复制成功');
            oInput.remove()
        },
        // 分屏
        getQueryString(name, defVal = "") {
            var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
            var r = window.location.search.substr(1).match(reg);
            if (r != null) {
                return unescape(r[2]);
            }
            return defVal;
        },
        clearVideos() {
            for (var idx in this.players) {
                this.closeVideo(idx);
            }
        },
        setPlayerLength(playerNum) {
            if (playerNum == this.players.length) {
                return
            }
            this.clearVideos();
            this.players = []
            this.playerLength = playerNum;
            for (let index = 0; index < this.playerLength; index++) {
                this.players.push({
                    url: "",
                    bLoading: false,
                    timer: 0,
                    bCloseShow: false,
                    closeTimer: 0
                })
            }
        },
        fullscreen(){
            this.$fullscreen.enter(this.$el.querySelector(`.video-show > div`), {
                wrap: false
            });
        },
        play(index, checkedCamera) {
            var i = 0;
            var player = null;
            for (var _player of this.players) {
                if (index == i) {
                    player = _player;
                    break;
                }
                i++
            }
            if (!player) {
                this.$message.error("当前播放窗口已被占满！");
                return;
            }
            player.bLoading = true;
            cameraAjax.get(`${checkedCamera.movieurls}api/v1/stream/start`, {
                params:{
                    serial: checkedCamera.national_num,
                    code: checkedCamera.channel,
                }
            }).then(res => {
                if(res == null){
                    player.bLoading = false;
                    player.url = "";
                }else{
                    player.url = res.data.FLV;
                    player.bLoading = false;
                }
            }).catch(() => {
                player.bLoading = false;
                this.$message.error("当前摄像头无画面，请更换摄像头");
                player.url = "";
            });
        },
        closeVideo(idx) {
            var player = this.players[idx];
            if (!player) {
                return;
            }
            if (player.closeTimer) {
                clearTimeout(player.closeTimer);
                player.closeTimer = 0;
            }
            player.bCloseShow = false;
            player.bloading = false;
            player.url = "";
        },
        resetCloseTimer(player) {
            player.bCloseShow = true;
            if (player.closeTimer) {
                clearTimeout(player.closeTimer)
            }
            player.closeTimer = setTimeout(() => {
                player.bCloseShow = false;
            }, 3000);
        },
        selectChannel(index) {
            this.index = index;
            this.showCameradeviceLevelData = true;
            this.getCameradeviceLevelData();
            this.getCameraList(1);
        },
        getCameradeviceLevelData(){
            const deviceLevelData = this.$store.state.deviceLevelData;
            if(deviceLevelData){
                this.deviceLevelData = deviceLevelData;
            }else{
                this.getDeviceGroup();
            };
        },
        getDeviceGroup(){
            deviceGroup().then(res => {
                if(res.code === 1){
                    this.changeLevelData(res.data.list)
                    this.deviceLevelData = res.data.list;
                    // 存储摄像头层级数据
                    this.$store.commit('getDeviceLevelData',this.deviceLevelData);
                }
            })
        },
        changeLevelData(data){
            data.forEach(item => {
                item.scopedSlots = {
                    "title":"custom"
                };
                if(item.children.length > 0){
                    this.changeLevelData(item.children);
                }
            });
        },
        clickGroup(selectedKeys, info){
            this.filterId =  selectedKeys[0] || '';
            this.getCameraList(1);
            console.log(selectedKeys, info);
        },
        filterDeviceGroup(data){ // 按设备层级筛选
            this.filterId =  data.id ? data.id : "";
            this.getCameraList(1);
        },
        searchDevice(){
            this.keyword = this.search;
            this.getCameraList(1);
        },
        changePage(page){
            this.getCameraList(page)
        },
        getCameraList(page){ // 获取摄像头列表
        this.loading = true;
            if(page){
                this.currentPage = page;
            }
            getCameraList({
                page: this.currentPage,
                pageSize: this.pageSize,
                device_group_id: this.filterId,
                search: this.keyword,
            }).then(res => {
                this.loading = false;
                if(res.code === 1){
                    this.total = res.data.total;
                    let tableData = this.$getData(res.data.data);
                    this.tableData = tableData.map((item,index) => {
                        item.index = this.$getIndex(index,this.currentPage,this.pageSize,this.total);
                        return item;
                    })
                }
            }).catch(() => {
                this.loading = false;
            })
        },
        click(record, index){
            return {
                on: {
                    click: () => {
                        this.showCameradeviceLevelData = false;
                        this.play(this.index,record);
                    }
                }
            }
        },
        back(){
            this.$emit('close');
        },
        changeDate(date){   
            this.$nextTick(() => {
                this.$refs.playback.changeDate(date);
            })
        }
    },
    mounted(){
        this.shareHref = '//dt.skyinforcloud.com/cameraShare/liveVideo.html'+`?serial=${this.checkedCamera.national_num}&code=${this.checkedCamera.channel}&movieurls=${this.checkedCamera.movieurls}`
    },
    
}
</script>

<style lang="scss" scoped>
.content{
    height: 100%;
    display: flex;
    flex-direction: column;
    .header{
        background: #fff;
        padding:30px 0 0 30px;
        .back-box{
            display:flex;
            align-items: center;
            .back{
                width:32px;
                height:32px;
                border: 1px solid #D9D9D9;
                border-radius: 50%;
                box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                ::v-deep .anticon-left{
                    color:rgba(0, 0, 0, 0.85);
                }
            }
            .title{
                margin-left:30px;
                font-size: 20px;
                line-height: 28px;
                color:rgba(0, 0, 0, 0.85);
            }
        }
        .cameraTab{
            display:flex;
            margin-top:46px;
            .camera-tab{
                text-align: center;
                margin-right: 60px;
                cursor: pointer;
                font-size:16px;
                line-height: 30px;
                color:rgba(0, 0, 0, .7);
            }
            .active{
                color: #1890FF;
                border-bottom: 2px solid #1890FF;
            }
        }
    }
    .camera-tabpanes{
        margin:30px 0 0 30px;
        flex:1;
        display: flex;
        justify-content: space-between;
        overflow-y: auto;
    }
    .video-box,.videotape{
        width:72.6%;
        max-width:1325px;
        display: flex;
        flex-direction: column;
    }
    .device-frame{
        position: relative;
        flex:1;
        display: flex;
        justify-content: center;
        .controller{
            width:240px;
            .controller-top{
                display:flex;
                justify-content: space-between;
                .ptz-cell{
                    width:60px;
                    height:60px;
                    border-radius: 50%;
                }
                .ptz-zoomin{
                    background: url("../../../assets/images/Subtractmin.png") no-repeat;
                    background-size: cover;
                }
                .ptz-zooout{
                    background: url("../../../assets/images/Subtract.png") no-repeat;
                    background-size: cover;
                }
                .ptz-zoomin:active{
                    background: url("../images/SubtractAmin.png") no-repeat;
                    background-size: cover;
                }
                .ptz-zooout:active{
                    background: url("../images/SubtractA.png") no-repeat;
                    background-size: cover;
                }
            }
            .controller-bottom{
                margin-top:50px;
                display:flex;    
                flex-direction: column;
                align-items: center;
                .ptz-box{
                    width:100%;
                    margin:30px 0;
                    display: flex;
                    justify-content: space-between;
                }
                .ptz-cell{
                    width:60px;
                    height:60px;
                }
                .ptz-left{
                    background: url("../../../assets/images/Subtractleft.png") no-repeat;
                    background-size: cover;
                }
                .ptz-right{
                    background: url("../../../assets/images/Subtractr.png") no-repeat;
                    background-size: cover;
                }
                .ptz-up{
                    background: url("../../../assets/images/Subtractup.png") no-repeat;
                    background-size: cover;
                }
                .ptz-down{
                    background: url("../../../assets/images/Subtractd.png") no-repeat;
                    background-size: cover;
                }
                .ptz-left:active{
                    background: url("../images/SubtractAl.png") no-repeat;
                    background-size: cover;
                }
                .ptz-right:active{
                    background: url("../images/SubtractAr.png") no-repeat;
                    background-size: cover;
                }
                .ptz-up:active{
                    background: url("../images/SubtractAup.png") no-repeat;
                    background-size: cover;
                }
                .ptz-down:active{
                    background: url("../images/SubtractAd.png") no-repeat;
                    background-size: cover;
                }
            }
        }
    }
    .date-box{
        flex:1;
        padding:0 2.1%;
        .title{
            font-size: 16px;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.7);
        }
        ::v-deep .ant-calendar-picker{
            width:320px;
            margin-top:16px;
        }
    }
    .share{
        flex:1;
        margin:0 2.1%;
        .title{
            font-size: 16px;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.7);
        }
        .share-href{
            margin:16px 0 30px;
        }
        .camera-href-box {
            display: flex;
            height: 32px;
            max-width:300px;
            border: 1px solid #D9D9D9;
            font-size: 12px;
            align-items: center;
            border-radius: 2px;
            div {
                line-height: 32px;
                padding: 0 5px;
                overflow: hidden;
                white-space:nowrap;
                text-overflow:ellipsis;
                flex:1;
            }
            ::v-deep .anticon-copy{
                margin-right:12px;
            }
        }
        .QRcode{
            margin-top:16px;
            p{
                text-align: center;
                margin:12px 0 0;
                color:rgba(0, 0, 0, 0.5);
                font-size:12px;
            }
        }
    }
    .view-list {
        margin-top:30px;
        width:72.6%;
        .video-show{
            .player{
                display:flex;
                flex-wrap: wrap;
                .video{
                    border: 1px solid #979797;
                    position: relative
                }
                @media (max-width: 1680px){
                    .col-sm-12{
                        width:100%;
                    }
                }
                @media (min-width: 1680px){
                    .col-sm-12{
                        width:90%;
                    }
                }
                .col-sm-6{
                    width:43%;
                    margin-right:30px;
                    margin-bottom:20px;
                }
                .col-sm-4{
                    width:28%;
                    margin-right:20px;
                    margin-bottom:20px;
                }
            }
            .fullscreen{
                width: 100% !important;
                .video{
                    margin-bottom: 0;
                    margin-right:0;
                    box-sizing: border-box;
                    ::v-deep .player-wrapper{
                        height:100%;
                    }
                    ::v-deep .video-wrapper{
                        height: 100%;
                        padding-bottom: 0 !important;
                    }
                }
                .col-sm-12{
                    width:100%;
                    height:100%;
                }
                .col-sm-6{
                    width:50%;
                    height:50%;
                }
                .col-sm-4{
                    width:33.3%;
                    height:33.3%;
                }
                .col-sm-3{
                    width:25%;
                    height:25%;
                }
            }
            // .fullscreen{
            //     width: 100% !important;
            //     .col-sm-12{
            //         width:100%;
            //     }
            //     .col-sm-6{
            //         width:48%;
            //     }
            //     .col-sm-4{
            //         width:32%;
            //     }
            // }
        }
    }
    .video-close {
        position: absolute;
        top: 5px;
        right: 5px;
        color: white;
        font-size: 12px;
        background-color: fade(gray, 50%);
        padding: 2px 5px;
        cursor: pointer;
        border-radius: 2px;
        max-width: 120px;
    }

    .player-btn-group {
        ::v-deep .ant-btn{
            width:88px;
            margin-right:20px;
        }
        .active {
            color: #fff;
            background-color:#1890FF;
            border-color:#1890FF;
        }
    }
    .fullscreen{
        width:1500px;
        height:1000px;
    }
    .footer{
        text-align: center;
        width:705px;
    }
}
.modal-btns{
    text-align:right;
}
.modal{
    z-index:2001;
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0,0,0,.3);
    .modal-dialog{
        width:698px;
        position: relative;
        left:50%;
        top:50%;
        transform: translate(-50%,-50%);
        background: #fff;
        .modal-content{
            padding: 20px 20px 15px;
            height: 493px;
            .tableLeft{
                width:200px;
                ::v-deep .ant-tree{
                    >li{
                        padding:0 !important;
                    }
                    .ant-tree-child-tree > li{
                        padding:0 !important;
                    }
                    .tree-view-item{
                        padding-right:5px;
                    }
                    .tree-view-left{
                        display:block;
                        flex:1;
                        overflow: hidden;
                        text-overflow:ellipsis;
                        white-space: nowrap;
                    }
                    .ant-tree-node-content-wrapper{
                        width:calc(100% - 24px);
                        .ant-tree-title{
                            width:100%;
                            .tree-view-item{
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                .tree-view-left{
                                    color:#404040;
                                }
                            }
                        }
                        .anticon-more:hover{
                            color:#1890FF;
                        }
                    }
                    .ant-tree-treenode-selected{
                        background: #E6F7FF !important;
                        .anticon-caret-dow,.ant-tree-switcher-icon{
                            color:#1890FF !important;
                        }
                        .ant-tree-child-tree{
                            background: #fff;
                        }
                    }
                    .ant-tree-node-content-wrapper{
                        height:36px;
                        line-height:36px;
                        padding:0;
                    }
                    .anticon-caret-dow,.ant-tree-switcher-icon{
                        line-height:36px;
                        color:rgba(0, 0, 0, 0.85);
                    }
                    .ant-tree-treenode-switcher-close:hover{
                        background: #f5f7fa;
                        .anticon-caret-dow,.ant-tree-switcher-icon{
                            color:rgba(0, 0, 0, 0.85);
                        }
                    }
                    .ant-tree-node-selected{
                        background: transparent;
                    }
                    .ant-tree-node-content-wrapper:hover{
                        background: transparent;
                    }
                }
            }
            .tableRight{
                flex:1;
                display: flex;
                flex-direction: column;
                ::v-deep .ant-table-wrapper{
                    flex:1;
                }
                ::v-deep .ant-table {
                    .ant-table-thead > tr > th, .ant-table-tbody > tr > td{
                        padding:9px 8px;
                    }
                }
                ::v-deep .ant-pagination{
                    margin:15px 0;
                    text-align: right;
                }
            }
        }
    }
}
</style>